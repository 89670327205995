<template>
  <div class="space-y-4 lg:space-y-6">
    <div v-if="!noLink" class="flex items-center">
      <h1 class="text-xl sm:text-2xl lg:text-3xl font-bold flex-grow">
        <TheNuxtLink :href="`${locale}/updates`">
          {{ $t("components.namiLatestUpdates.heading") }}
        </TheNuxtLink>
      </h1>
      <NamiButton
        button-type="secondary"
        text
        pill
        :icon="IconArrowRight"
        :href="`${locale}/updates`"
      />
    </div>
    <div v-else class="flex items-center">
      <h1 class="text-xl sm:text-2xl lg:text-3xl font-bold flex-grow">
        {{ $t("components.namiLatestUpdates.heading") }}
      </h1>
    </div>
    <div
      v-if="pending"
      class="grid responsive-gap grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4"
    >
      <Skeleton v-for="_ in Array(items)" class="h-56 w-full" />
    </div>
    <!-- grid display -->
    <ul
      v-else-if="titles"
      role="list"
      class="grid responsive-gap grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4"
    >
      <li
        v-for="{ title, chapters, cover, organization } in titles"
        class="overflow-hidden responsive-gap grid grid-cols-[8rem_1fr] sm:grid-cols-[10rem_1fr] tile p-0"
      >
        <TheNuxtLink :to="linkTo(title)" class="block">
          <img
            :src="getCoverUrl(title.id, cover.attributes.fileName, '512')"
            class="w-full h-full aspect-[5/7] rounded-md object-cover shadow-md"
          />
        </TheNuxtLink>
        <div
          class="min-w-[0] w-full grid grid-rows-[auto_1fr] py-2 pr-2 md:pb-4 md:pr-4"
        >
          <TheNuxtLink :to="linkTo(title)" class="mb-2">
            <h3
              class="line-clamp-1 sm:line-clamp-2 sm:text-lg font-semibold"
              style="word-break: break-word"
            >
              {{ extractFromLocale(locale, title.attributes.title) }}
            </h3>
          </TheNuxtLink>
          <div class="">
            <template v-for="(chapter, index) in chapters" :key="chapter.id">
              <NamiDivider v-if="index > 0" class="!my-2" />
              <ChapterCard
                class="x-small transition-colors canhover:hover:bg-neutral-200 canhover:dark:hover:bg-neutral-700 active:bg-neutral-200 dark:active:bg-neutral-700"
                :chapter="chapter"
                :organization="organization"
                :fallback-url="
                  getCoverUrl(title.id, cover.attributes.fileName, '512')
                "
                :title-id="title.id"
                :is-locked="!(latestUpdatesGuestGating?.[chapter.id] ?? true)"
                :has-access="latestUpdatesUserGating?.[chapter.id] ?? false"
                no-stats
                transparent
                small
              />
            </template>
          </div>
        </div>
      </li>
    </ul>
    <div v-else-if="error" class="text-red-500">
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Chapter,
  Gating,
  getLocalizedCoverFromTitleAndCoverRelationships,
  getRelationship,
  isExpanded,
  type ChapterRelation,
  type CoverRelation,
  type OrganizationRelation,
  type PopulateRelationship,
  type TitleRelation,
  isCoverR,
} from "~/src/api";
import { IconArrowRight } from "@tabler/icons-vue";

const nuxtApp = useNuxtApp();
const { locale } = nuxtApp.$i18n.global;
const settingsStore = nuxtApp.$settings();
const { getCoverUrl } = useMediaLink();
const isLoggedIn = useIsLoggedIn();

const props = defineProps<{
  noLink?: boolean;
  items: number;
}>();

const {
  pending,
  data: titles,
  error,
} = useAsyncData(
  "new-chapters",
  async () => {
    const userLanguages =
      settingsStore?.settings.platform.preferredTranslationLocales ?? [];

    const chapterUpdates = await Chapter.getLastUpdated({
      translatedLanguages:
        userLanguages.length > 0 ? userLanguages : ALL_LANGUAGES,
      includes: ["title", "chapter", "cover_art", "organization"],
    });

    const latestUpdatesMap: Record<
      string,
      {
        title: PopulateRelationship<TitleRelation>;
        chapters: PopulateRelationship<ChapterRelation>[];
        cover: PopulateRelationship<CoverRelation>;
        organization: PopulateRelationship<OrganizationRelation>;
      }
    > = {};

    for (const chapterUpdate of chapterUpdates.data) {
      const title = getRelationship(chapterUpdate, "title", true);
      const chapter = getRelationship(chapterUpdate, "chapter", true);
      const covers = chapterUpdate.relationships
        .filter(isCoverR)
        .filter(isExpanded);
      const cover = getLocalizedCoverFromTitleAndCoverRelationships(
        locale.value,
        title,
        covers,
      );
      const organization = getRelationship(chapterUpdate, "organization", true);

      if (Object.keys(latestUpdatesMap).length === props.items) break;

      if (latestUpdatesMap[title.id]) {
        latestUpdatesMap[title.id].chapters.push(chapter);
      } else {
        latestUpdatesMap[title.id] = {
          title,
          chapters: [chapter],
          cover,
          organization,
        };
      }
    }

    return latestUpdatesMap;
  },
  { server: false },
);

const {
  status: latestUpdatesGuestGatingStatus,
  data: latestUpdatesGuestGating,
  error: latestUpdatesGuestGatingError,
} = useAsyncData(
  `latest-updates-guest-gating-check`,
  async () => {
    if (titles.value === null) return null;

    const chapterUpdatesOfTitlesWithGating = Object.values(titles.value).filter(
      ({ title }) => title.attributes.gating !== null,
    );
    const chaptersInGatedTitles = chapterUpdatesOfTitlesWithGating.flatMap(
      ({ chapters }) => chapters,
    );

    if (chaptersInGatedTitles.length === 0) return {};

    const guestAccess = await Gating.checkEntityAccess(
      chaptersInGatedTitles.map(({ id }) => ({
        entityType: "chapter",
        entityId: id,
      })),
    );

    return guestAccess.attributes.map;
  },
  { server: false, watch: [titles] },
);

const {
  status: latestUpdatesUserGatingStatus,
  data: latestUpdatesUserGating,
  error: latestUpdatesUserGatingError,
} = useAsyncData(
  `latest-updates-user-gating-check`,
  async () => {
    if (titles.value === null) return null;
    if (!isLoggedIn.value) return {};

    const chapterUpdatesOfTitlesWithGating = Object.values(titles.value).filter(
      ({ title }) => title.attributes.gating !== null,
    );
    const chaptersInGatedTitles = chapterUpdatesOfTitlesWithGating.flatMap(
      ({ chapters }) => chapters,
    );

    if (chaptersInGatedTitles.length === 0) return {};

    const userAccess = await Gating.checkEntityAccess(
      chaptersInGatedTitles.map(({ id }) => ({
        entityType: "chapter",
        entityId: id,
      })),
      await getTokenOrThrow(),
    );

    return userAccess.attributes.map;
  },
  { server: false, watch: [titles] },
);
</script>
